import React from 'react';
import CustomGatsbyImage from '../../global/customGatsbyImage';
import PortableText from '@sanity/block-content-to-react';
import {serializers} from '../../../utils/portableTextSerializers';
import SectionContainer from '../../layout/sectionContainer';
import SectionWrapper from '../../layout/sectionWrapper';

const About = ({features, featuresButton, keyFigures}) => {
  return (
    <SectionWrapper>
      <div id="a-propos" style={{position: 'relative', top: '-50px'}} />
      <SectionContainer customClasses="pt-24 pb-16 md:pt-28 md:pb-20">
        <div className="flex flex-col items-center">
          <div className="flex flex-col pb-10 space-y-10 text-base leading-relaxed text-gray-600 lg:space-y-6 md:text-xl max-w-prose">
            {features.map(f => <PortableText
              key={f._key}
              blocks={f._rawFeature}
              serializers={serializers}
            />
            )}
          </div>
        </div>
        {keyFigures.length > 0 && <div className="flex flex-wrap justify-center gap-2 mt-24 xl:gap-0">
          {keyFigures.map(kf => {
            return (
              <div
                key={kf._key}
                className="relative md:max-w-1/2 lg:max-w-1/3 xl:max-w-1/4"
              >
                <CustomGatsbyImage image={kf.image} customClasses="w-full h-full" />
                <div className="absolute inset-0 bg-black opacity-50" />
                <div className="absolute z-10 flex flex-col justify-center w-full p-4 text-white transform -translate-x-1/2 -translate-y-1/2 text-centeritems-center top-1/2 left-1/2">
                  <h3 className="text-4xl font-bold text-center uppercase">{kf.number}</h3>
                  <p className='text-lg text-center uppercase'>{kf.description}</p>
                </div>
              </div>
            );
          })}
        </div>}
      </SectionContainer>
    </SectionWrapper>
  );
};

export default About;
