import React, {useState, useEffect} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import Header from '../components/header/header';
import HeroSection from '../components/sections/homepage/heroSection';
import AboutSection from '../components/sections/homepage/aboutSection';
import {hasWindow} from '../utils/hasWindow';
import NewsSection from '../components/sections/homepage/newsSection';
import TestimonialsSection from '../components/sections/homepage/testimonialsSection';

const DOMAIN = "fetedelascience-paysdelaloire.fr"

const transformUrlToRelativeIfInternal = (url) => {
  if (!url) return null;
  const isInternal = url.includes(DOMAIN);
  if (isInternal) {
    const urlWithoutProtocol = url.split('//')[1];
    const urlWithoutProtocolAndDomain = urlWithoutProtocol.split(
      DOMAIN
    )[1];
    return urlWithoutProtocolAndDomain;
  } else {
    return url;
  }
}

const IndexPage = ({data}) => {
  const {
    features,
    featuresButton,
    featuresTitle,
    heroButton,
    heroUrl,
    hideNews,
    keyFigures,
    showFavorites,
    showProgrammeButtons,
  } = data.sanityPage.pageContent[0];
  const news = data.news;
  const testimonials = data.testimonials.edges;
  const events = data.favorites.edges;
  const heroButtonUrl = transformUrlToRelativeIfInternal(heroUrl);
  const [showHeader, setShowHeader] = useState(false);
  const [lastYPos, setLastYPos] = useState(0);
  const [randomSortedEvents, setRandomSortedEvents] = useState([]);
  const handleScroll = (e) => {
    setLastYPos(window.scrollY);
  };
  useEffect(() => {
    const sixRandomSortedEvents =
      events
        .sort(() => Math.random() - Math.random())
        .slice(0, 3)
        .map((event) => event.node);
    setRandomSortedEvents(sixRandomSortedEvents);
  }, []);

  // Define event listener on scroll
  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // Define when the nav should appear on screen
  useEffect(() => {
    if (lastYPos >= 300) {
      setShowHeader(true);
    } else if (lastYPos <= 400) {
      setShowHeader(false);
    }
  }, [lastYPos]);
  const areNewsHidden = hideNews || news.edges.length === 0;
  const areFavoritesHidden = !showFavorites || randomSortedEvents.length === 0;
  const showNewsSection = !areNewsHidden || (areNewsHidden && !areFavoritesHidden);
  return (
    <Layout headerHidden={true}>
      <SEO title="Accueil" />
      <Header isVisible={showHeader} isHomepage />
      <HeroSection
        heroButton={heroButton}
        heroUrl={heroButtonUrl}
        fdsLogo={data.fdsLogo}
        videoBackground={data.videoBackground}
        dates={data.dates}
        showProgrammeButtons={showProgrammeButtons}
      />
      {showNewsSection && <NewsSection news={news} hideNews={hideNews} showFavorites={showFavorites} favorites={randomSortedEvents} />}
      <AboutSection
        features={features}
        featuresButton={featuresButton}
        featuresTitle={featuresTitle}
        keyFigures={keyFigures}
      />
      {testimonials.length > 0 && <TestimonialsSection testimonialNodes={testimonials} />}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePage {
    sanityPage(pageName: { eq: "Homepage" }) {
      pageContent {
        ... on SanityHomePageBlock {
          features {
            image {
              asset {
                gatsbyImageData(width: 500)
              }
              hotspot {
                x
                y
              }
            }
            _rawFeature
            _key
          }
          keyFigures {
            _key
            image {
              asset {
                gatsbyImageData(width: 500)
              }
            }
            number
            description
          }
          featuresButton
          featuresTitle
          heroButton
          showProgrammeButtons
          heroUrl
          hideNews
          showFavorites
        }
      }
    }
    videoBackground: sanitySiteSettings {
      videoLink
      videoPlaceholder {
        asset {
          url
        }
      }
    }
    dates: sanitySiteSettings {
      startDate
      endDate
    }
    fdsLogo: sanitySiteSettings {
      fdsLogoWhite {
        asset {
          url
        }
      }
    }
    news: allSanityNews(
      limit: 3
      sort: {order: DESC, fields: date}
      filter: {showOnHomepage: {eq: true}}
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData(width: 500)
            }
            hotspot {
              x
              y
            }
          }
          summary
        }
      }
    }
    testimonials: allSanityTestimonials(
      limit: 5
      sort: {order: DESC, fields: _createdAt}
    ) {
      edges {
        node {
          text
          author
        }
      }
    }
    favorites: allSanityEvent(filter: {featured: {eq: true}}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          village {
            id
            title
            timeSlots {
              endDate
              endTime
              startDate
              startTime
            }
          }
          department {
            name
          }
          featured
          eventCanceled
          _rawDescription
          description {
            children {
              text
            }
          }
          theme {
            id
            name
          }
          timeSlots {
            endDate
            endTime
            startDate
            startTime
          }
          format {
            id
            name
            formatIcon {
              asset {
                gatsbyImageData(width: 500)
              }
            }
          }
          image {
            asset {
              url
              gatsbyImageData(width: 1200)
            }
            hotspot {
              x
              y
            }
          }
          audience {
            id
            name
          }
          audienceCustom {
            from
            to
          }
        }
      }
    }
  }
`;
