import React from 'react';
import CustomGatsbyImage from '../../global/customGatsbyImage';
import {Link} from 'gatsby';
import SectionContainer from '../../layout/sectionContainer';
import {EventMiniCard} from '../villageShow/eventsListSection';

const NewsEntry = ({node}) => (
  <div className="flex flex-col w-full gap-3 p-3 bg-white rounded shadow-lg max-w-11/12 md:max-w-xs pb-7">
    <CustomGatsbyImage image={node.image} customClasses="h-36 flex-shrink-0" />
    <h2 className="font-bold text-gray-700">{node.title}</h2>
    <p className="h-full">{node.summary}</p>
    <Link to={`/actualites/${node.slug.current}`} className="inline-flex items-center px-4 py-2 font-medium leading-5 text-white transition duration-150 ease-in-out rounded-sm text-md w-fit bg-primary hover:bg-primary hover:text-white focus:outline-none focus:border-indigo-700 active:bg-primary">Lire la suite</Link>
  </div>
)

const FavoritesEntry = ({favorites}) => (
  <div className="flex flex-col w-full gap-1 p-3 bg-white rounded shadow-lg max-w-11/12 md:max-w-xs">
    <h2 className="mb-2 font-bold text-gray-700">Sélection de nos coups de coeur ❤️</h2>
    {favorites.map(node => (
      <EventMiniCard
        event={node}
        key={node.id}
      />
    ))}
  </div>
)

const NewsSection = ({news, hideNews, showFavorites, favorites}) => {
  if (hideNews) return null;
  return (
    <SectionContainer customClasses="relative z-10 flex flex-col items-center justify-center gap-8 pt-2 mx-auto -mt-16 lg:-mt-20 xl:max-w-screen-xl lg:flex-row lg:items-stretch">
      {showFavorites && <FavoritesEntry favorites={favorites} />}
      {news.edges.map(({node}, index) => <NewsEntry node={node} key={`${node.slug.current}-${index}`} />)}
    </SectionContainer>
  )
}

export default NewsSection;