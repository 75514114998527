import React, {useState, useRef} from 'react';
import {Link, graphql, useStaticQuery} from 'gatsby';
import styled from 'styled-components';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import Transition from '../../global/transition';
import HeaderMobileContent from '../../header/headerMobileContent';
import SectionWrapper from '../../layout/sectionWrapper';
import LogoFdsDefault from '../../../images/logo-fds-rouge-white.svg';
import useClickOutside from '../../../hooks/useClickOutside';
import {GatsbyImage} from 'gatsby-plugin-image';

const ContentCenter = styled.div``;

const departmentLinks = [
  {label: 'Loire-Atlantique', slug: 'loire-atlantique'},
  {label: 'Maine-et-Loire', slug: 'maine-et-loire'},
  {label: 'Mayenne', slug: 'mayenne'},
  {label: 'Sarthe', slug: 'sarthe'},
  {label: 'Vendée', slug: 'vendee'},
  {label: 'Scolaires', slug: 'scolaires'}
]

const ScolaireLogo = ({className}) => (
  <svg
    className={`w-7 h-7 ${className}`}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.71875 21.2303L15.5 25.0448L22.2812 21.2303V17.2002L24.2188 16.1238V22.3634L15.5 27.2677L6.78125 22.3634V16.1238L8.71875 17.2002V21.2303Z"
      fill="currentColor"
    />
    <path
      d="M15.5 2.78375L29.0625 9.81615V11.4954L15.5 19.0299L3.875 12.5718V17.9219H1.9375V9.81615L15.5 2.78375ZM5.8125 11.4317L7.75 12.5081L15.5 16.8139L23.25 12.5081L25.1875 11.4317L26.5304 10.6857L15.5 4.96622L4.46963 10.6857L5.8125 11.4317Z"
      fill="currentColor"
    />
  </svg>
)

const HeroSection = ({
  heroButton,
  showProgrammeButtons,
  heroUrl,
  videoBackground,
  dates,
  fdsLogo,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityRoute(sort: {order: ASC, fields: order}) {
        edges {
          node {
            label
            link
            hide
          }
        }
      }
      sanityPage(pageName: { eq: "Homepage" }) {
        pageContent {
          ... on SanityHomePageBlock {
            leftLogo {
                asset {
                  gatsbyImageData(width: 500)
                }
            }
            leftLogoWidth
            rightLogo {
                asset {
                  gatsbyImageData(width: 500)
                }
            }
            rightLogoWidth
            showRedCircle
          }
        }
      }
    }
  `)
  const deparmementsLinkNode = data.allSanityRoute.edges.find(edge => edge.node.link === '/departements/')?.node;
  const departementsLabel = deparmementsLinkNode?.label;
  const departementsHide = deparmementsLinkNode?.hide;
  const editorialLinkNode = data.allSanityRoute.edges.find(edge => edge.node.link === '/editorial/')?.node;
  const editorialLabel = editorialLinkNode?.label;
  const editorialHide = editorialLinkNode?.hide;
  const mainLinks = ['/', '/editorial/', '/departements/', '/scolaires/']
  const otherLinks = data.allSanityRoute.edges.filter(edge => !mainLinks.includes(edge.node.link));
  const [isDepartmentActive, setIsDepartmentActive] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const leftLogo = data.sanityPage.pageContent[0]?.leftLogo?.asset.gatsbyImageData;
  const leftLogoWidth = data.sanityPage.pageContent[0]?.leftLogoWidth;
  const showRedCircle = data.sanityPage.pageContent[0]?.showRedCircle;
  const rightLogo = data.sanityPage.pageContent[0]?.rightLogo?.asset.gatsbyImageData;
  const rightLogoWidth = data.sanityPage.pageContent[0]?.rightLogoWidth;

  const LogoFds = fdsLogo?.fdsLogoWhite?.asset?.url || LogoFdsDefault;

  const startDate = format(new Date(dates.startDate), 'dd MMMM', {
    locale: fr,
  });
  const endDate = format(new Date(dates.endDate), 'dd MMMM Y', {
    locale: fr,
  });

  // handle department menu click
  const depMenuRef = useRef();
  useClickOutside(depMenuRef, () => setIsDepartmentActive(false));

  return (
    <SectionWrapper backgroundColor="bg-teal-500 min-h-screen relative flex flex-col">
      <video
        autoPlay
        playsInline
        loop
        muted
        poster={videoBackground.videoPlaceholder.asset.url}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: '0',
        }}
      >
        <source
          src={`https://drive.google.com/uc?export=download&id=${videoBackground.videoLink}`}
          type="video/mp4"
        />
      </video>
      {/* Video Background filter Start */}
      <div
        className="absolute inset-0 z-10"
        style={{
          background: 'rgb(42, 74, 81)',
          opacity: 0.9,
        }}
      />
      {/* Video Background filter End */}

      {/* NAVBAR START */}
      <div className="absolute top-0 left-0 right-0 z-40">
        <div className="relative flex items-start justify-between">
          <div className="relative flex items-center w-auto pr-4 max-w-1/2 sm:w-56 md:w-4/12 lg:w-56 xl:w-2/12" >
            {showRedCircle && <div className="absolute w-full h-full -mt-8 -ml-8 pt-full" style={{background: '#dd505f', borderRadius: 999999999}} />}
            <GatsbyImage
              image={leftLogo}
              className="w-full" alt=""
              style={{width: `${leftLogoWidth}px`}}
            />
          </div>
          {/* <img
            data-name="logo-ministere"
            src={logoMinister}
            alt="ministère de l'enseignement supérieur"
            className="w-auto pr-4 max-w-1/2 sm:w-56 md:w-4/12 lg:w-56 xl:w-2/12"
          /> */}
          <div className="flex items-center justify-between flex-grow ">
            {/* NAVIGATION MENU START */}
            <div data-name="top-menu" className="flex-grow lg:mt-10">
              <div
                data-name="nav-desktop"
                className="justify-center hidden lg:flex"
              >
                <nav className="flex items-center px-6 space-x-10">
                  {!editorialHide && (<Link
                    to="/editorial"
                    className="text-xl font-medium leading-6 text-white transition duration-150 ease-in-out hover:border-b-2 hover:border-white focus:outline-none focus:text-white focus:shadow-outline-indigo"
                  >
                    {editorialLabel}
                  </Link>)}

                  {!departementsHide && (<div className="relative">
                    <button
                      type="button"
                      onClick={() => setIsDepartmentActive(!isDepartmentActive)}
                      className={`${isDepartmentActive ? 'text-white' : 'text-white'
                        } group inline-flex items-center space-x-2 text-xl leading-6 font-medium hover:border-b-2 hover:border-white focus:outline-none focus:shadow-outline-indigo focus:text-white transition ease-in-out duration-150`}
                    >
                      <span>{departementsLabel}</span>
                      <svg
                        className={`${isDepartmentActive ? 'text-white' : 'text-white'
                          } h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    <Transition
                      show={isDepartmentActive}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <div
                        ref={depMenuRef}
                        className="absolute z-50 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                      >
                        <div className="rounded-lg shadow-lg">
                          <div className="overflow-hidden rounded-lg shadow-xs">
                            <div className="relative z-20 flex-col px-5 pt-2 pb-6 text-center bg-white">
                              <Link
                                to="/loire-atlantique"
                                className="block py-4 transition duration-150 ease-in-out border-b border-gray-200 hover:bg-gray-100"
                              >
                                <p className="text-lg font-medium leading-6 text-gray-900">
                                  Loire Atlantique
                                </p>
                              </Link>
                              <Link
                                to="/maine-et-loire"
                                className="block py-4 transition duration-150 ease-in-out border-b border-gray-200 hover:bg-gray-100"
                              >
                                <p className="text-lg font-medium leading-6 text-gray-900">
                                  Maine et Loire
                                </p>
                              </Link>
                              <Link
                                to="/mayenne"
                                className="block py-4 transition duration-150 ease-in-out border-b border-gray-200 hover:bg-gray-100"
                              >
                                <p className="text-lg font-medium leading-6 text-gray-900">
                                  Mayenne
                                </p>
                              </Link>
                              <Link
                                to="/sarthe"
                                className="block py-4 transition duration-150 ease-in-out border-b border-gray-200 hover:bg-gray-100"
                              >
                                <p className="text-lg font-medium leading-6 text-gray-900">
                                  Sarthe
                                </p>
                              </Link>
                              <Link
                                to="/vendee"
                                className="block pt-4 pb-6 transition duration-150 ease-in-out hover:bg-gray-100"
                              >
                                <p className="text-lg font-medium leading-6 text-gray-900">
                                  Vendée
                                </p>
                              </Link>
                              <Link
                                to="/scolaires"
                                className="flex items-center justify-center px-4 py-2 space-x-1 text-sm font-bold leading-6 text-gray-700 uppercase whitespace-no-wrap transition duration-150 ease-in-out border border-transparent rounded-md -flex bg-edu hover:bg-eduLight focus:outline-none focus:shadow-outline-indigo active:bg-bg-eduLight"
                              >
                                <ScolaireLogo />
                                <span>Scolaires</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>)}
                  {otherLinks.map((linkEdge) => {
                    const node = linkEdge.node;
                    const hide = node.hide;
                    const label = node.label;
                    const linkUrl = node.link;
                    return !hide ? (
                      <Link to={linkUrl} key={linkUrl} className="text-xl font-medium leading-6 text-white transition duration-150 ease-in-out hover:border-b-2 hover:border-white focus:outline-none focus:text-white focus:shadow-outline-indigo">{label}</Link>
                    ) : null
                  })}
                </nav>
              </div>

            </div>
            {/* NAVIGATION MENU END */}
            <div className="self-end w-auto pl-4 pr-2 mt-10 max-w-3/4 sm:w-56 md:w-3/6 lg:w-56 md:ml-0 md:mr-8 xl:w-auto">
              <div className="hidden xl:block"><GatsbyImage
                image={rightLogo}
                alt="Logo de droite"
                style={{width: `${rightLogoWidth}px`}}
              />
              </div>
              <div className="xl:hidden">
                <GatsbyImage
                  image={rightLogo}
                  alt="Logo de droite"
                />
              </div>
            </div>
          </div>
        </div>
        <Transition
          show={isMobileMenuOpen}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <HeaderMobileContent setIsMobileMenuOpen={setIsMobileMenuOpen} />
        </Transition>
      </div>
      {/* NAVBAR END */}
      {/* ****************** */}
      {/* ****************** */}

      {/* CENTER BLOCK */}
      <div className="z-30 flex flex-col justify-between flex-auto">
        {/* NAV PADDING START */}
        <div className="h-40 md:h-52 lg:h-40 2xl:h-52" />
        {/* NAV PADDING END */}
        {/* CTA BLOCK START */}
        <section className="flex items-center self-center justify-center flex-grow w-full max-w-3xl px-6 pb-28 xl:pb-36 xl:max-w-4/5 2xl:pb-20 2xl:max-w-8xl md:px-14 sm:px-6 lg:px-8">
          <ContentCenter
            data-name="content-center"
            className="flex flex-col items-center flex-grow"
          >
            <img
              src={LogoFds}
              alt="fête de la science"
              id="logo-fds"
              className="w-10/12 md:w-6/12 md:mt-0 md:h-full"
            />

            <div className="mb-10 text-center text-white md:mb-12">
              <h3 className="text-4xl font-bold tracking-tight md:text-5xl xl:text-7xl">
                Pays de la Loire
              </h3>

              <p className="text-lg uppercase md:text-2xl 2xl:text-3xl">
                {startDate && <span>{startDate}</span>}
                {endDate && <span> - {endDate} </span>}
              </p>
            </div>
            {!showProgrammeButtons && heroButton && (
              <span className="inline-flex flex-shrink-0 rounded-md shadow-sm">
                <Link
                  to={heroUrl || '/'}
                  className="inline-flex items-center px-3 py-3 text-lg font-bold leading-6 text-center text-white uppercase transition duration-150 ease-in-out transform border border-transparent rounded-md xl:text-2xl xl:px-4 xl:py-4 2xl:px-6 2xl:py-6 2xl:text-3xl bg-primary hover:bg-primary focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-primary hover:scale-105"
                >
                  {heroButton}
                </Link>
              </span>
            )}
            {showProgrammeButtons && (
              <div><p className="mb-4 text-lg text-center text-white uppercase">Accéder au programme :</p>
                <div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
                  {departmentLinks.map((departement) => (
                    <Link
                      key={departement.slug}
                      to={'/' + departement.slug}
                      className={`inline-flex items-center px-3 py-3 text-lg font-bold leading-6 text-center uppercase transition duration-150 ease-in-out transform border border-transparent rounded-md w-fit xl:px-4 xl:py-4 ${departement.slug !== 'scolaires' ? "bg-edu text-black":"bg-primary hover:bg-primary text-white"} focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-primary hover:scale-105`}
                    >
                      {departement.slug === 'scolaires' && <ScolaireLogo className="mr-1" />}{departement.label}
                    </Link>
                  ))}
                </div>
              </div>
            )}

          </ContentCenter>
        </section>
        {/* CTA BLOCK END */}

      </div>
    </SectionWrapper>
  );
};

export default HeroSection;
