import React from 'react';
import TestimonialsCarousel from './testimonialsCarousel';
import SectionContainer from '../../layout/sectionContainer';
import { RiDoubleQuotesL,RiDoubleQuotesR } from 'react-icons/ri';

const TestimonialsSection = ({testimonialNodes}) => {
  const testimonials = testimonialNodes.map(({node}) => node);
  return (
    <SectionContainer customClasses="mb-24 relative">
      <div className="relative flex flex-col items-center max-w-3xl mx-auto">
        <RiDoubleQuotesL className="absolute z-0 text-5xl text-yellow-400 -top-6 left-1/12 md:text-7xl"  />
        <TestimonialsCarousel testimonials={testimonials} />
        <RiDoubleQuotesR className="absolute z-0 text-5xl text-yellow-400 right-1/12 md:text-7xl -bottom-4"/>
      </div>
    </SectionContainer>)
};

export default TestimonialsSection;