import React, {useState, useEffect} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import styled from 'styled-components';
import tw from 'twin.macro';

const CarouselContainer = styled.div`
  display: flex !important;
  justify-content: center !important;
  .carousel-root {
    ${tw`w-full`}
  }
  .carousel {
    border-radius: 5px;
    color: #23153d;

    .myCarousel-item {
      ${tw`flex flex-col items-center justify-center h-full max-w-prose`}
      .item-text-content {
        ${tw`py-6`}
      }
    }

    .control-arrow {
      ${tw`opacity-100 hover:bg-white`}
    }
    .control-prev.control-arrow::before {
      border-right: 8px solid #23153d;
    }
    .control-next.control-arrow::before {
      border-left: 8px solid #23153d;
    }
    .slide {
      ${tw`py-4 text-black md:px-12 font-cursive max-w-prose`}
    }
    .control-dots {
      ${tw`-bottom-4`}
    }
    .dot {
      ${tw`w-3 h-3 mt-12 bg-yellow-500 shadow-none opacity-50`}
    }
    .dot.selected {
      ${tw`opacity-100`}
    }
  }
`;

const TestimonialsCarousel = ({testimonials}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsMobile(window.innerWidth <= 640);
    }
  }, []);
  return (
    <CarouselContainer
      data-styled="carousel-container"
      className="z-10 max-w-full py-4 mx-auto md:py-0 lg:max-w-prose"
    >
      <Carousel
        autoPlay
        infiniteLoop
        interval={10000}
        showStatus={false}
        showArrows={!isMobile}
        swipeable={false}
        showThumbs={false}
      >
        {testimonials.map((testimonial, i) => (
          <div key={i} className="myCarousel-item max-w-prose">
            <div className="flex flex-col items-center justify-center h-full text-gray-700 max-w-prose">
              <p className="text-2xl">{testimonial.text}</p>
              <p className="mt-3 font-sans text-xl font-bold">{testimonial.author}</p>
            </div>
          </div>)
        )}
      </Carousel >
    </CarouselContainer >
  );
};

export default TestimonialsCarousel;
